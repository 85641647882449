<template>
  <div class="process-block" :class="{'process-block-row': showType === 'row', 'process-block-column': showType === 'column'}">
    <div class="menus">
      <a class="m-item" :href="'#' + item.key" v-for="item in (haveScrap ? menus : menus.slice(0, menus.length - 1))" :key="item.key">
        {{item.label}}
      </a>
    </div>
    <div class="content">
      <assets v-if="!haveScrap" :all-show-detail="showDetail" :showType="showType" :data="data"></assets>
      <scrap v-else :all-show-detail="showDetail" :showType="showType" :data="data"></scrap>
    </div>
    <div class="detail-icon" @click="showDetail = !showDetail">
      <i class="iconfont icon-Invoice1"></i>
    </div>
    <div class="switch-icon" @click="showType === 'row' ? showType = 'column' : showType = 'row'">
      <i class="iconfont icon-paidui"></i>
    </div>
  </div>
</template>

<script>
import {
  assetsRequest
} from '../../../api'

import assets from './process/assets'
import scrap from './process/scrap'

export default {
  components: {
    assets,
    scrap
  },
  props: {
    assetsId: { type: Number, default: null }
  },
  watch: {
    assetsId: {
      deep: true,
      handler () {
        this.loadData()
      },
      immediate: true
    }
  },
  data () {
    return {
      // row column
      haveScrap: false,
      data: {},
      showDetail: false,
      showType: 'row',
      menus: [{
        key: 'budget',
        label: '预算'
      },
      {
        key: 'purchase',
        label: '采购申报'
      },
      {
        key: 'purchase_method',
        label: '采购过程'
      },
      {
        key: 'purchase_imp',
        label: '采购执行'
      },
      {
        key: 'assets',
        label: '资产'
      },
      {
        key: 'scrap',
        label: '报废'
      }]
    }
  },
  methods: {
    async loadData () {
      if (!this.assetsId) {
        return
      }
      let data = await assetsRequest.getProcess(this.assetsId)
      if (data.assetsScrapList && data.assetsScrapList.length > 0) {
        let scrap = data.assetsScrapList[0]
        this.haveScrap = true
        scrap.assetsProcess = Object.assign({}, data, {
          assetsScrapList: null
        })
        this.data = scrap
      } else {
        this.data = data
        this.haveScrap = false
      }
    }
  }
}
</script>

<style scoped lang="less">
.process-block {
  width: 100%;
  height: 100%;
  position: relative;
  .switch-icon {
    position: absolute;
    transition: 0.5s;
    transform:rotate(90deg);
    right: 5px;
    top: 0;
    width: 40px;
    text-align: center;
    background: #fff;
    border-radius: 3px;
    height: 40px;
    cursor: pointer;
    text-align: center;
    i {
      line-height: 40px;
      font-size: 30px;
      color: #F4628F;
    }
  }
  .detail-icon {
    position: absolute;
    cursor: pointer;
    right: 50px;
    top: 0;
    height: 40px;
    width: 40px;
    text-align: center;
    background: #fff;
    border-radius: 3px;
    widows: 40px;
    text-align: center;
    i {
      line-height: 40px;
      font-size: 30px;
      color: #F4628F;
    }
  }
  .menus {
    display: flex;
  }
  .m-item {
    flex: 1;
    color: #515a6e;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .content {
    overflow: auto;
  }
}
.process-block-row {
  display: block;
  .menus {
    padding-right: 40px;
    height: 50px;
  }
  .content {
    width: 100%;
    padding: 10px;
    height: calc(100% - 70px);
  }
}
.process-block-column {
  display: flex;
  .menus {
    width: 100px;
    flex-direction: column;
  }
  .switch-icon {
    transform:rotate(0deg);
  }
  .content {
    width: calc(100% - 120px);
    padding: 10px;
    height: calc(100% -20px);
  }
}
</style>