<template>
  <div class="purchase-detail-bdi" v-if="budgetDetail" :class="{'purchase-detail-bdi-row': showType === 'row', 'purchase-detail-bdi-column': showType === 'column'}">
    <div>
      <div class="t-info-text" style="margin-bottom: -20px;">预算{{budgetDetail.code}}比重</div>
      <div class="p-chart">
        <div class="chart-item">
          <rate :data="budgetDetail.rate1Data"></rate>
        </div>
        <div class="chart-item">
          <rate :data="budgetDetail.rate2Data"></rate>
        </div>
      </div>
    </div>
    <div class="detail-list">
      <div class="t-info-text" v-if="budgetDetail.purchaseBatchDetailList.length > 0">该笔预算关联采购</div>
      <div style="display: flex;">
        <div class="budget-info-2" v-for="(v, index) in budgetDetail.purchaseBatchDetailList" :key="index">
          <div class="info-text-2">采购ID：{{v.code}}</div>
          <div class="info-text-2">名称：{{v.goodsName}}<i v-if="budgetDetail.goodsId !== v.goodsId" class="fmico fmico-info-solid"></i></div>
          <div class="info-text-2">申请部门：{{v.orgName}}<i v-if="budgetDetail.budgetApplyOrgId !== v.orgId" class="fmico fmico-info-solid"></i></div>
          <div class="info-text-2">单价：{{v.price}}元 <i v-if="v.price > budgetDetail.price" class="fmico fmico-paixu-shengxu"></i></div>
          <div class="info-text-2">数量：{{v.num}}<i v-if="budgetDetail.rate2Data.warning" class="fmico fmico-paixu-shengxu"></i></div>
          <div class="info-text-2">总价：{{v.total}}元<i v-if="budgetDetail.rate1Data.warning" class="fmico fmico-paixu-shengxu"></i></div>
          <div class="info-text-2"></div>
        </div>
      </div>
    </div>
    <div>
      <div class="t-info-text">审批记录</div>
      <record :record-list="budgetDetail && budgetDetail.statusActionRecord ? budgetDetail.statusActionRecord : []"></record>
    </div>
  </div>
  <div v-else style="margin: 20px;">无预算</div>
</template>

<script>
import rate from './rate'
import record from './record'

import {
  budgetDetailRequest
} from '../../../../api'

export default {
  components: {
    rate,
    record
  },
  props: {
    budgetDetailId: { type: Number, defualt: null },
    showType: {
      type: String,
      default: 'row'
    }
  },
  watch: {
    budgetDetailId: {
      deep: true,
      handler () {
        this.loadData()
      },
      immediate: true
    }
  },
  data () {
    return {
      budgetDetail: []
    }
  },
  methods: {
    getRate1Data (item, title, key) {
      let data = {
        warning: false,
        title: title,
        data: [],
        dw: key === 'num' ? '' : '元',
        color: ['#F5E2DF', '#FFCFD7', '#FF9EA3']
      }
      let totalM = item[key]
      let usedTotalM = 0
      let usedList = item.purchaseBatchDetailList.map(v => {
        usedTotalM += v[key] || 0
        return {
          name: v.code,
          value: v[key] || 0
        }
      })
      if (totalM === usedTotalM) {
        data.data = usedList;
      } else if (totalM > usedTotalM) {
        data.data = usedList;
        data.data.push({
          name: '剩余',
          value: totalM - usedTotalM,
          itemStyle: {
            color: '#A0DBE3'
          },
          label: {
            show: true,
            position: 'center',
            formatter: function (param) {
              return param.name + ' ' + param.value + (data.dw || '')
            }
          }
        })
      } else {
        data.warning = true
        data.data.push({
          name: '超出',
          value: usedTotalM - totalM,
          itemStyle: {
            color: '#FB6D8F'
          },
          label: {
            show: true,
            position: 'center',
            formatter: function (param) {
              return param.name + ' ' + param.value + (data.dw || '')
            }
          }
        })
      }
      return data
    },
    async loadData () {
      this.budgetDetail = null
      if (!this.budgetDetailId) {
        return
      }
      let datas = await budgetDetailRequest.get({
        needPurchaseBatchDetail: 1,
        ids: this.budgetDetailId,
        statusActionRecord: 1
      })
      datas.forEach(v => {
        v.rate1Data = this.getRate1Data(v, '总价', 'total')
        v.rate2Data = this.getRate1Data(v, '数量', 'num')
      })
      this.budgetDetail = datas.length > 0 ? datas[0] : null
    }
  }
}
</script>

<style scoped lang="less">
.purchase-detail-bdi-row {
  flex-direction: column;
}
.purchase-detail-bdi-column {
  flex-direction: row;
}
.purchase-detail-bdi {
  display: flex;
  margin: 10px 0;
  .t-info-text {
    font-weight: 600;
    padding: 0 10px;
  }
  .budget-info-2 {
    padding: 5px 10px 0;
    margin-bottom: 10px;
    .info-text-2 {
      font-size: 12px;
      white-space:nowrap;
      line-height: 22px;
      display: flex;
      justify-content: space-between;
      margin-right: 10px
    }
    i {
      color: #FB6D8F;
      padding-left: 10px;
    }
  }
  .p-chart {
    height: 180px;
    display: flex;
    .chart-item {
      width: 150px;
      height: 100%;
    }
  }
}
</style>