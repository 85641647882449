<template>
  <div class="repair-block">
    <div style="width: 100%;height: 100%;">
    <fm-table-new
      :simple-filter="true"
      :columns="columnList"
      :auto-height="true"
      border="row"
      :handler-counter="countFun"
      @counterChange="countDataChange"
      :data-list="dataList"
      :stripe="false"
      emptyPlaceholder="-">
    </fm-table-new>
    </div>
  </div>
</template>

<script>
import {
  assetsInRequest
} from '../../../api'


export default {
  props: {
    assetsId: { type: Number, default: null }
  },
  watch: {
    assetsId: {
      deep: true,
      handler () {
        this.loadData()
      },
      immediate: true
    }
  },
  data () {
    return {
      dataList: [],
      total: null
    }
  },
  computed: {
    columnList: {
      get () {
        let data = [{
          title: '库存记录编号',
          field: 'code',
          width: '130',
          sort: true
        },
        {
          title: '资产编号',
          field: 'assetsCode',
          sort: true
        },
        {
          title: '资产名称',
          field: 'assetsName',
          sort: true
        },
        {
          title: '规格型号',
          field: 'assetsGgxh',
          sort: true
        },
        {
          title: '价值',
          dataType: Number,
          field: 'assetsJz',
          sort: true
        },
        {
          title: '入库机构',
          field: 'inOrgName',
          sort: true
        },
        {
          title: '入库日期',
          field: 'inTime',
          dataType: Date,
          sort: true,
          render: (h, rowData) => {
            return h('div', rowData && rowData.inTime ? rowData.inTime.slice(0, 10) : '-')
          }
        },
        {
          title: '出库机构',
          field: 'outOrgName',
          sort: true
        },
        {
          title: '出库日期',
          field: 'outTime',
          dataType: Date,
          sort: true,
          render: (h, rowData) => {
            return h('div', rowData && rowData.outTime ? rowData.outTime.slice(0, 10) : '-')
          }
        },
        {
          title: '状态',
          field: 'status',
          sort: true
        }]
        return data
      }
    }
  },
  methods: {
    countDataChange (d) {
      this.total = d
      this.modal = true
    },
    countFun (data) {
      return data.length
    },
    async loadData () {
      if (!this.assetsId) {
        return
      }
      this.dataList = await assetsInRequest.get({
        assetsId: this.assetsId
      })
      this.dataList.forEach(v => {
        v.status = v.outTime ? '已出库' : '存储中'
      })
    }
  }
}
</script>

<style scoped lang="less">
.repair-block {
  width: 100%;
  height: 100%;
}
</style>