<template>
  <div class="process-tmp" :class="{'process-tmp-row': showType === 'row', 'process-tmp-column': showType === 'column'}">
    <div class="other-info" v-if="detailList && detailList.length > 0">
      <div class="other-info-item" v-for="(item, index) in detailList" :key="item.id">
        <component
          v-bind:is="cmp"
          :all-show-detail="allShowDetail"
          :showType="showType"
          :data="item">
        </component>
        <div class="line-div">
          <div class="line"></div>
        </div>
        <div class="c-line-div"
          v-if="dataLength > 1"
          :class="{'c-line-div-t': index == 0 && dataLength > 1, 'c-line-div-c': index != 0 && dataLength - 1 > index, 'c-line-div-b': index != 0 && dataLength - 1 === index}">
          <div class="line"></div>
        </div>
      </div>
    </div>
    <div class="process-info-c">
      <div class="line-div" v-if="detailList && detailList.length > 0">
        <div class="line"></div>
      </div>
      <div class="process-info-block">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'processItemTmp',
  props: {
    showType: {
      type: String,
      default: 'row'
    },
    allShowDetail: {
      type: Boolean,
      default: false
    },
    cmp: {
      type: Object
    },
    detailList: {
      type: Array,
      default: function () {return []}
    }
  },
  computed: {
    dataLength () {
      return this.detailList ? this.detailList.length : 0
    }
  }
}
</script>

<style scoped lang="less">
@border-size: 1px;
@border-color: rgba(0, 0, 0, 0.15);
.process-tmp {
  .line {
    background: @border-color;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15);
  }
}

.process-tmp-row {
  display: flex;
  .other-info {
    display: flex;
    flex-direction: column;
  }
  .other-info-item {
    display: flex;
    align-items: flex-start;
    position: relative;
  }
  .process-info-block {
    margin-bottom: 20px;
    flex-direction: column;
  }
  .line-div {
    min-width: 30px;
    flex:1;
    height: @border-size;
    margin-top: 50px;
    .line {
      width: 100%;
      height: 100%;
    }
  }
  .c-line-div {
    position: absolute;
    right: 0;
    width: @border-size;
    .line {
      width: 100%;
      height: 100%;
      background: @border-color;
    }
  }
  .c-line-div-t {
    top: 50px;
    bottom: 0;
  }
  .c-line-div-c {
    top: 0;
    bottom: 0;
  }
  .c-line-div-b {
    top: 0;
    height: 50px;
  }
}
.process-tmp-column {
  display: flex;
  flex-direction: column;
  .other-info {
    display: flex;
  }
  .process-info-c {
    flex-direction: column;
  }
  .other-info-item {
    display: flex;
    flex-direction: column;
    justify-items: flex-start;
    position: relative;
  }
  .process-info-block {
    margin-right: 20px;
  }
  .line-div {
    min-height: 30px;
    flex:1;
    width: @border-size;
    margin-left: 50px;
    .line {
      min-height: 30px;
      width: 100%;
      height: 100%;
    }
  }
  .c-line-div {
    position: absolute;
    bottom: 0;
    height: @border-size;
    .line {
      width: 100%;
      height: 100%;
    }
  }
  .c-line-div-t {
    left: 50px;
    right: 0;
  }
  .c-line-div-c {
    left: 0;
    right: 0;
  }
  .c-line-div-b {
    left: 0;
    width: 50px;
  }
}
.process-info-block {
  cursor: pointer;
  display: flex;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
}
.process-info-c {
  display: flex;
  align-items: flex-start;
}
</style>