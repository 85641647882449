<template>
  <div class="repair-block">
    <div style="width: 100%;height: 100%;">
    <fm-table-new
      :simple-filter="true"
      :columns="columnList"
      :auto-height="true"
      border="row"
      :handler-counter="countFun"
      @counterChange="countDataChange"
      :data-list="dataList"
      :stripe="false"
      emptyPlaceholder="-">
      <table-actions slot="actions" @table-action="tableAction" slot-scope="{ row, index }" :data="tableActions" :index="index" :row="row"></table-actions>
    </fm-table-new>
    </div>
    <fm-modal title="维修申请详细" v-model="modal" width="1000px" v-if="modal && chooseData">
      <repairApplyDetail v-if="chooseData.assetsRepairApplyId" :statusMap="statusMap" :status-config="statusConfig" :id="chooseData.assetsRepairApplyId"></repairApplyDetail>
      <div style="text-align: center;line-height: 100px;font-size: 16px;" v-else>未走维修申请流程</div>
    </fm-modal>
  </div>
</template>

<script>
import {
  assetsRepairRequest,
  statusManageRequest
} from '../../../api'

import TextCell from '@/components/base/TextCell'
import TableActions from '@/components/base/TableActions'
import repairApplyDetail from '../../repair/apply/detail'

export default {
  components: {
    TableActions,
    repairApplyDetail
  },
  props: {
    assetsId: { type: Number, default: null }
  },
  created () {
    this.loadConfig()
  },
  watch: {
    assetsId: {
      deep: true,
      handler () {
        this.loadData()
      },
      immediate: true
    }
  },
  data () {
    return {
      dataList: [],
      total: null,
      chooseData: null,
      tableActions: [{key: 'apply', label: '相关申请'}],
      modal: false,
      statusMap: {},
      statusConfig: []
    }
  },
  computed: {
    columnList: {
      get () {
        let data = [{
          title: '维修记录编号',
          field: 'code',
          sort: true
        },
        {
          title: '资产编号',
          field: 'assetsCode',
          sort: true
        },
        {
          title: '资产名称',
          field: 'assetsName',
          sort: true
        },
        {
          title: '规格型号',
          field: 'assetsGgxh',
          sort: true
        },
        {
          title: '价值',
          dataType: Number,
          field: 'assetsJz',
          sort: true
        },
        {
          title: '使用机构',
          field: 'assetsUseOrgName',
          sort: true
        },
        {
          title: '维修厂家',
          field: 'repairCmp',
          sort: true
        },
        {
          title: '维修开始日期',
          field: 'startTime',
          dataType: Date,
          sort: true,
          render: (h, rowData) => {
            return h('div', rowData && rowData.startTime ? rowData.startTime.slice(0, 10) : '-')
          }
        },
        {
          title: '维修结束日期',
          field: 'endTime',
          dataType: Date,
          sort: true,
          render: (h, rowData) => {
            return h('div', rowData && rowData.endTime ? rowData.endTime.slice(0, 10) : '-')
          }
        },
        {
          title: '维修明细',
          field: 'detail',
          sort: true,
          render: (h, row) => {
            return h(TextCell, {
              props: {
                value: row.detail
              }
            })
          }
        },
        {
          title: '状态',
          field: 'statusText',
          sort: true
        },
        {
          title: '操作', slot: 'actions',
          width: 70,
          fixed: 'right',
          search: false,
          export: false
        }]
        return data
      }
    }
  },
  methods: {
    tableAction (parm) {
      this.chooseData = parm.data
      this.modal = true
    },
    countDataChange (d) {
      this.total = d
      this.modal = true
    },
    countFun (data) {
      return data.length
    },
    async loadData () {
      if (!this.assetsId) {
        return
      }
      this.dataList = await assetsRepairRequest.get({
        assetsId: this.assetsId
      })
      this.dataList.forEach(v => v.statusText = v.status === 'doing' ? '维修中' : '完成维修')
    },
    async loadConfig () {
      let c = await statusManageRequest.config({
        statusGroupKey: 'assetsRepairApply'
      })
      c.statusList.forEach(v => {
        this.statusMap[v.statusKey] = v.label
      })
      this.statusConfig = {
        norm: c.statusSwitchList.filter(v => v.isHost && (!v.tag || v.tag === 'norm')),
        mini: c.statusSwitchList.filter(v => v.isHost && (!v.tag || v.tag === 'mini')),
      }
    },
  }
}
</script>

<style scoped lang="less">
.repair-block {
  width: 100%;
  height: 100%;
}
</style>