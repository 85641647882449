<template>
  <process-item-tmp
    :cmp="cmp"
    :detail-list="[data.assetsProcess]"
    :allShowDetail="allShowDetail"
    :show-type="showType">
    <base-info id="scrap" @click="showDetail= !showDetail" :data="showData" :config="config"></base-info>
    <scrap-info :show-type="showType" v-if="showDetail && showData && showData.id" :id="showData.id"></scrap-info>
  </process-item-tmp>
</template>

<script>
import processItemTmp from './processItemTmp'
import assets from './assets'
import baseInfo from './baseInfo'
import scrapInfo from './scrapInfo'

export default {
  components: {
    processItemTmp,
    scrapInfo,
    baseInfo
  },
  props: {
    showType: {
      type: String,
      default: 'row'
    },
    allShowDetail: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: function () {return null}
    }
  },
  watch: {
    allShowDetail: {
      deep: true,
      handler () {
        this.showDetail = this.allShowDetail
      },
      immediate: true
    }
  },
  computed: {
    showData () {
      let data = Object.assign({}, this.data)
      data.passTime = (data.statusActionRecord.find(v => v.newStatusKey === 'end' && !v.isBack) || {createTime: ''}).createTime
      return data
    }
  },
  data () {
    return {
      showDetail: false,
      cmp: assets,
      config: [{
        key: 'id',
        label: '资产编号',
        format: (data) => {
          return data.assetsProcess.code
        }
      },
      {
        key: 'goodsName',
        label: '资产名称',
        format: (data) => {
          return data.assetsProcess.name
        }
      },
      {
        key: 'applyOrgName',
        label: '申请机构'
      },
      {
        key: 'type',
        label: '报废类型',
        format: (data) => {
          return data.type === 'norm' ? '正常报废' : '异常报废'
        }
      },
      {
        key: 'remark',
        label: '报废原因'
      },
      {
        key: 'passTime',
        label: '报废日期',
        format: (data) => {
          return data.passTime ? data.passTime.slice(0, 10) : ''
        }
      },
      {
        key: 'jz',
        label: '残余价值'
      }]
    }
  }
}
</script>
