<template>
  <process-item-tmp
    :cmp="cmp"
    :detail-list="data.purchase ? [data.purchase] : []"
    :all-show-detail="allShowDetail"
    :show-type="showType">
    <base-info id="assets" :data="data" :config="config"></base-info>
  </process-item-tmp>
</template>

<script>
import processItemTmp from './processItemTmp'
import baseInfo from './baseInfo'
import purchase from './purchase'

export default {
  components: {
    processItemTmp,
    baseInfo
  },
  props: {
    showType: {
      type: String,
      default: 'row'
    },
    allShowDetail: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: function () {return null}
    }
  },
  data () {
    return {
      cmp: purchase,
      config: [{
        key: 'code',
        label: '资产编号'
      },
      {
        key: 'name',
        label: '资产名称'
      },
      {
        key: 'useOrgName',
        label: '使用机构'
      },
      {
        key: 'useStatus',
        label: '使用状态'
      },
      {
        key: 'getTime',
        label: '取得日期',
        format: (data) => {
          return data.getTime ? data.getTime.slice(0, 10) : ''
        }
      },
      {
        key: 'jz',
        label: '价值'
      },
      {
        key: 'num',
        label: '数量',
        format: () => {
          return 1
        }
      }]
    }
  }
}
</script>
