<template>
  <div class="detail-bdi" v-if="id" :class="{'detail-bdi-row': showType === 'row', 'detail-bdi-column': showType === 'column'}">
    <div v-for="(item, index) in purchaseContract" :key="index">
      <base-info id="purchase_contract" :data="item" :config="config"></base-info>
    </div>
    <div class="p-acc" v-for="(item, index) in acceptanceData" :key="'ys' + index">
      <div class="t-info-text" style="margin-bottom: 5px;">验收信息</div>
      <accept-info :data="item"></accept-info>
    </div>
  </div>
</template>

<script>
import baseInfo from './baseInfo'
import acceptInfo from '../../../accept/components/detail'

import {
  purchaseContractRequest,
  acceptanceRequest
} from '../../../../api'

export default {
  components: {
    baseInfo,
    acceptInfo
  },
  props: {
    id: { type: Number, defualt: null },
    showType: {
      type: String,
      default: 'row'
    }
  },
  watch: {
    id: {
      deep: true,
      handler () {
        this.loadData()
      },
      immediate: true
    }
  },
  data () {
    return {
      purchaseContract: [],
      acceptanceData: [],
      config: [{
        key: 'code',
        label: '采购合同'
      },
      {
        key: 'name',
        label: '合同名称'
      },
      {
        key: 'principalUserName',
        label: '采购负责人'
      },
      {
        key: 'signedOrg',
        label: '签订单位'
      },
      {
        key: 'signedTime',
        label: '签订日期',
        format: (data) => {
          return data.signedTime ? data.signedTime.slice(0, 10) : ''
        }
      },
      {
        key: 'total',
        label: '合同金额'
      },
      {
        key: 'num',
        label: '采购数量'
      }]
    }
  },
  methods: {
    async loadData () {
      this.purchaseContract = []
      this.acceptanceData = []
      if (!this.id) {
        return
      }
      this.purchaseContract = await purchaseContractRequest.get({
        purchaseId: this.id
      })
      this.acceptanceData = await acceptanceRequest.get({
        purchaseId: this.id
      })
    }
  }
}
</script>

<style lang="less">
.p-acc {
  .table-div {
      table {
      width: calc(100% - 10px);
      padding-left: 10px;
    }
  }
}
</style>

<style scoped lang="less">
.detail-bdi-row {
  flex-direction: column;
}
.detail-bdi-column {
  flex-direction: row;
}
.detail-bdi {
  display: flex;
  margin: 10px 0;
  .t-info-text {
    font-weight: 600;
    padding: 0 10px;
  }
  .budget-info-2 {
    padding: 5px 10px 0;
    margin-bottom: 10px;
    .info-text-2 {
      font-size: 12px;
      white-space:nowrap;
      line-height: 22px;
      display: flex;
      justify-content: space-between;
      margin-right: 10px
    }
    i {
      color: #FB6D8F;
      padding-left: 10px;
    }
  }
  .p-chart {
    height: 180px;
    display: flex;
    .chart-item {
      width: 150px;
      height: 100%;
    }
  }
}
</style>