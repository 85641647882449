<template>
  <process-item-tmp
    :cmp="cmp"
    :detail-list="data.budgetDetailList || []"
    :allShowDetail="allShowDetail"
    :show-type="showType">
    <base-info id="purchase" @click="showDetail= !showDetail" :data="showData" :config="config"></base-info>
    <purchase-detail-info :show-type="showType" v-if="showDetail && showData && showData.id" :id="showData.id"></purchase-detail-info>
  </process-item-tmp>
</template>

<script>
import processItemTmp from './processItemTmp'
import budgetDetail from './budgetDetail'
import baseInfo from './baseInfo'
import purchaseDetailInfo from './purchaseDetailInfo'

export default {
  components: {
    processItemTmp,
    purchaseDetailInfo,
    baseInfo
  },
  props: {
    showType: {
      type: String,
      default: 'row'
    },
    allShowDetail: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: function () {return null}
    }
  },
  watch: {
    allShowDetail: {
      deep: true,
      handler () {
        this.showDetail = this.allShowDetail
      },
      immediate: true
    }
  },
  computed: {
    showData () {
      let data = Object.assign({}, this.data)
      data.passTime = (data.statusActionRecord.find(v => v.newStatusKey === 'end' && !v.isBack) || {createTime: ''}).createTime
      return data
    }
  },
  data () {
    return {
      showDetail: false,
      cmp: budgetDetail,
      config: [{
        key: 'code',
        label: '采购编号'
      },
      {
        key: 'goodsName',
        label: '物品名称'
      },
      {
        key: 'busOrgName',
        label: '申报科室'
      },
      {
        key: 'reason',
        label: '申请理由'
      },
      {
        key: 'passTime',
        label: '通过日期',
        format: (data) => {
          return data.passTime ? data.passTime.slice(0, 10) : ''
        }
      },
      {
        key: 'total',
        label: '总价'
      },
      {
        key: 'num',
        label: '数量'
      }]
    }
  }
}
</script>
