<template>
  <div class="ledger-base-info" @click="$emit('click')">
    <div class="info-item" :class="{'info-item-title': index === 0}" v-for="(item, index) in config" :key="item.key">
      <div class="label">
        {{item.label}}：
      </div>
      <div class="value">
        {{ item.format ? item.format(data) : data[item.key]}}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    config: {
      type: Array,
      default: function () {return []}
    },
    data: {
      type: Object,
      default: function () {return {}}
    }
  }
}
</script>

<style scoped lang="less">
.ledger-base-info {
  padding: 10px;
  color: #515a6e;
  .info-item-title {
    font-weight: 600;
  }
  .info-item {
    display: flex;
    .label {
      white-space:nowrap;
    }
    .value {
      white-space:nowrap;
    }
  }
}
</style>