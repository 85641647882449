<template>
  <process-item-tmp
    :cmp="cmp"
    :detail-list="data.purchaseMethodBatchDetailPurchaseList || []"
    :allShowDetail="allShowDetail"
    :show-type="showType">
    <base-info id="purchase_imp" @click="showDetail= !showDetail" :data="data" :config="config"></base-info>
    <purchase-info :show-type="showType" v-if="showDetail && data && data.id" :id="data.id"></purchase-info>
  </process-item-tmp>
</template>

<script>
import processItemTmp from './processItemTmp'
import purchaseMethodDetail from './purchaseMethodDetail'
import baseInfo from './baseInfo'
import purchaseInfo from './purchaseInfo'

export default {
  components: {
    processItemTmp,
    purchaseInfo,
    baseInfo
  },
  props: {
    showType: {
      type: String,
      default: 'row'
    },
    allShowDetail: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: function () {return null}
    }
  },
  watch: {
    allShowDetail: {
      deep: true,
      handler () {
        this.showDetail = this.allShowDetail
      },
      immediate: true
    }
  },
  data () {
    return {
      showDetail: false,
      cmp: purchaseMethodDetail,
      config: [{
        key: 'code',
        label: '采购结果'
      },
      {
        key: 'goodsName',
        label: '物品名称'
      },
      {
        key: 'pp',
        label: '品牌'
      },
      {
        key: 'endTime',
        label: '采购时间',
        format: (data) => {
          return data.endTime ? data.endTime.slice(0, 10) : ''
        }
      },
      {
        key: 'userName',
        label: '采购负责人'
      },
      {
        key: 'total',
        label: '总价'
      },
      {
        key: 'num',
        label: '数量'
      }]
    }
  }
}
</script>
